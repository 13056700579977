<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/news-til.png" alt="" srcset="" class="til">
        <img src="../../assets/images/wenli-3.png" alt="" srcset="" class="wl3">
        <div class="con">
            <p class="none">- 暂无数据 -</p>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    height: 1414px;
    .til{
        position: absolute;
        top: 120px;
        left: 182px;
        width: 233px;
        height: 156px;
        // margin: 120px 217px 0 182px;
    }
    .wl3{
        width: 1146px;
        height: 1043px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .con{
        height: 1188px;
        width: 1106px;
        margin: 120px 0 72px 632px;
        .none{
            font-size: 20px;
            text-align: left;
            color: #959595;
            padding-top: 10%;
            padding-left: 200px;
        }
    }
}
</style>